import { COLOR } from 'constants/appInfos';
import { PERSONAL_TITLE } from 'constants/personalTitle';

import {
    CircleCheckIco,
    CircleCrossIco,
    CircleExclamationMarkIco,
    FemaleProfilePicImg,
    MaleProfilePicImg
} from 'assets/images/common';
import {
    TitleMedalChiefIco,
    TitleMedalGeneralIco,
    TitleMedalSeniorIco
} from 'assets/images/home';

export const AVAILABLE_LANGUAGE = {
    EN: 'en',
    ZH: 'zh'
};

export const DOC_TYPE_INFO = {
    ID_CARD_FRONT: 'ID',
    ID_CARD_BACK: 'ID2',
    PASSPORT_FRONT: 'PP',
    PASSPORT_BACK: 'PP2',
    DRIVING_LICENSE_FRONT: 'DL',
    DRIVING_LICENSE_BACK: 'DL2',
    OTHER: 'OT',
    ADDRESS_PROOF: 'AD'
};

export const IDENTITY_TYPE = {
    ID_CARD: {
        key: 1,
        value: 'ID'
    },
    PASSPORT: {
        value: 'PP'
    },
    DRIVING_LICENSE: {
        value: 'DL'
    },
    OTHER: {
        value: 'OT'
    }
};

export const BOOLEAN_OPTION = {
    YES: {
        i18nKey: 'common.general.yes',
        value: true
    },
    NO: {
        i18nKey: 'common.general.no',
        value: false
    }
};

export const MT5_DOWNLOAD_LINK = {
    ANDROID: '/download/android/mt5',
    IOS: '/download/ios/mt5',
    WINDOWS: '/download/window/mt5',
    MAC: '/download/mac/mt5'
};

export const CTRADER_DOWNLOAD_LINK = {
    ANDROID: '/download/android/ctrader',
    IOS: '/download/ios/ctrader',
    WINDOWS: '/download/window/ctrader',
    MAC: '/download/mac/ctrader',
    PDF: '/document/cn/cTraderTutorialCN.pdf'
};

export const TRADING_PLATFORM = {
    MT5: 5,
    CTRADER: 100
};

export const APP_DOWNLOAD_LINK = {
    ANDROID: '/download/android/app',
    IOS: '/download/ios/app'
};

export const DESKTOP_APP_DOWNLOAD_LINK = {
    WINDOWS: '/download/window/app'
};

export const ACC_STATUS = {
    REGISTERED: 1,
    DOC_INFO_SUBMITTED: 3,
    MANAGER_REJECTED: 4,
    MANAGER_APPROVED: 5,
    REJECTED: 6,
    APPROVED: 7,
    AUDIT_FAILED: 80
};

export const DOC_STATUS = {
    NOT_SUBMITTED: 0,
    PENDING: 1,
    APPROVED: 2
};

export const INFO_STATUS = {
    NOT_SUBMITTED: 0,
    PENDING: 1,
    APPROVED: 2
};

export const ACCOUNT_TYPE = {
    STANDARD: {
        i18nKey: 'common.user.accountType.standard',
        serverKey: 'STANDARD'
    },
    MANAGER_STANDARD: {
        i18nKey: 'common.user.accountType.standard',
        serverKey: 'STANDARD'
    },
    PROFESSIONAL: {
        i18nKey: 'common.user.accountType.professional',
        serverKey: 'PROFESSIONAL'
    },
    ECN: {
        i18nKey: 'common.user.accountType.ecn',
        serverKey: 'ECN'
    }
};

export const BANK_ACCOUNT_STATUS = {
    PENDING: {
        i18nKey: 'common.bankAccountStatus.pending',
        serverKey: 1,
        StatusIcon: CircleExclamationMarkIco
    },
    FIRST_APPROVED: {
        i18nKey: 'common.bankAccountStatus.firstApproved',
        serverKey: 2,
        StatusIcon: CircleCheckIco
    },
    FIRST_REJECTED: {
        i18nKey: 'common.bankAccountStatus.firstRejected',
        serverKey: 3,
        StatusIcon: CircleCrossIco
    },
    REVIEW_APPROVED: {
        i18nKey: 'common.bankAccountStatus.reviewApproved',
        serverKey: 4,
        StatusIcon: CircleCheckIco
    },
    REVIEW_REJECTED: {
        i18nKey: 'common.bankAccountStatus.reviewRejected',
        serverKey: 5,
        StatusIcon: CircleCrossIco
    }
};

export const WALLET_ACCOUNT_STATUS = {
    PENDING: {
        i18nKey: 'common.bankAccountStatus.pending',
        serverKey: 1,
        StatusIcon: CircleExclamationMarkIco
    },
    FIRST_APPROVED: {
        i18nKey: 'common.bankAccountStatus.firstApproved',
        serverKey: 2,
        StatusIcon: CircleCheckIco
    },
    FIRST_REJECTED: {
        i18nKey: 'common.bankAccountStatus.firstRejected',
        serverKey: 3,
        StatusIcon: CircleCrossIco
    },
    REVIEW_APPROVED: {
        i18nKey: 'common.bankAccountStatus.reviewApproved',
        serverKey: 4,
        StatusIcon: CircleCheckIco
    },
    REVIEW_REJECTED: {
        i18nKey: 'common.bankAccountStatus.reviewRejected',
        serverKey: 5,
        StatusIcon: CircleCrossIco
    }
};

export const FUND_FLOW_TYPE = {
    DEPOSIT: {
        i18nKey: 'common.fundFlowTypes.deposit',
        serverKey: 'DEPOSIT'
    },
    WITHDRAW: {
        i18nKey: 'common.fundFlowTypes.withdraw',
        serverKey: 'WITHDRAW'
    },
    CREDIT_IN: {
        i18nKey: 'common.fundFlowTypes.creditIn',
        serverKey: 'CREDIT_IN'
    },
    CREDIT_OUT: {
        i18nKey: 'common.fundFlowTypes.creditOut',
        serverKey: 'CREDIT_OUT'
    },
    UNKNOWN: {
        i18nKey: 'common.fundFlowTypes.unknown',
        serverKey: 'UNKNOWN'
    }
};

export const FUND_FLOW_METHOD = {
    BANK_CARD: {
        i18nKey: 'common.fundFlowMethod.bankCard',
        serverKey: 'BANK_CARD'
    },
    CREDIT_CARD: {
        i18nKey: 'common.fundFlowMethod.creditCard',
        serverKey: 'CREDIT_CARD'
    },
    WIRE_TRANSFER: {
        i18nKey: 'common.fundFlowMethod.wireTransfer',
        serverKey: 'WIRE_TRANSFER'
    },
    REFUND: {
        i18nKey: 'common.fundFlowMethod.refund',
        serverKey: 'REFUND'
    },
    COMMISSION: {
        i18nKey: 'common.fundFlowMethod.commission',
        serverKey: 'COMMISSION'
    },
    BONUS: {
        i18nKey: 'common.fundFlowMethod.bonus',
        serverKey: 'BONUS'
    },
    INNER_TRANSFER_DEPOSIT: {
        i18nKey: 'common.fundFlowMethod.innerTransferDeposit',
        serverKey: 'INNER_TRANSFER_DEPOSIT'
    },
    OTHER_DEPOSIT: {
        i18nKey: 'common.fundFlowMethod.otherDeposit',
        serverKey: 'OTHER_DEPOSIT'
    },
    WITHDRAW: {
        i18nKey: 'common.fundFlowMethod.withdraw',
        serverKey: 'WITHDRAW'
    },
    INNER_TRANSFER_WITHDRAW: {
        i18nKey: 'common.fundFlowMethod.innerTransferWithdraw',
        serverKey: 'INNER_TRANSFER_WITHDRAW'
    },
    OTHER_WITHDRAW: {
        i18nKey: 'common.fundFlowMethod.otherWithdraw',
        serverKey: 'OTHER_WITHDRAW'
    },
    CREDIT_IN: {
        i18nKey: 'common.fundFlowMethod.creditIn',
        serverKey: 'CREDIT_IN'
    },
    CREDIT_OUT: {
        i18nKey: 'common.fundFlowMethod.creditOut',
        serverKey: 'CREDIT_OUT'
    },
    DEPOSIT: {
        i18nKey: 'common.fundFlowMethod.deposit',
        serverKey: 'DEPOSIT'
    },
    TRANSFER_FROM: {
        i18nKey: 'common.fundFlowMethod.transferFrom',
        serverKey: 'TRANSFER_FROM'
    },
    REJECT: {
        i18nKey: 'common.fundFlowMethod.reject',
        serverKey: 'REJECT'
    },
    COMPENSATION: {
        i18nKey: 'common.fundFlowMethod.compensation',
        serverKey: 'COMPENSATION'
    },
    BONUS_RESET_CREDIT: {
        i18nKey: 'common.fundFlowMethod.bonusResetCredit',
        serverKey: 'BONUS_RESET_CREDIT'
    },
    CHARGE_REFUND_WD_BANK: {
        i18nKey: 'common.fundFlowMethod.changeRefundBank',
        serverKey: 'CHARGE_REFUND_WD_BANK'
    },
    OTHERS: {
        i18nKey: 'common.fundFlowMethod.others',
        serverKey: 'OTHERS'
    },
    TRANSFER_TO: {
        i18nKey: 'common.fundFlowMethod.transferTo',
        serverKey: 'TRANSFER_TO'
    },
    RED_POCKET: {
        i18nKey: 'common.fundFlowMethod.redPocket',
        serverKey: 'RED_POCKET'
    }
};

export const INVITE_CODE_QUERY_KEY = {
    INVITE_CODE: 'i',
    CONTACT_ID: 'c',
    EMAIL: 'email',
    PHONE_CODE: 'phoneCtCode',
    MOBILE: 'phone',
    COUNTRY: 'country',
    DEMO: 'demo',
    PLATFORM: 'platform',
    SOURCE_CODE: 'sourceCode'
};

export const RESET_PASSWORD_QUERY_KEY = {
    ONE_TIME_TOKEN: 'verifier'
};

export const EMPLOYMENT_KEY = {
    EMPLOYED: 6,
    SELF_EMPLOYED: 4,
    RETIRED: 3,
    UNEMPLOYED: 5
};

export const COUNTRY_CODE = {
    CHINA: 'CN'
};

export const TRADE_STATUS = {
    CONFIRM: {
        serverKey: 2,
        i18nKey: 'common.status.confirm'
    },
    SUCCESS: {
        serverKey: 3,
        i18nKey: 'common.status.success'
    }
};

export const ORDER_ACTION = {
    BUY: {
        serverKey: 0,
        i18nKey: 'common.order.buy'
    },
    SELL: {
        serverKey: 1,
        i18nKey: 'common.order.sell'
    }
};

export const UNSELECTED_CLIENT_GROUP_ID = -1;

export const COMMISSION_TYPE = {
    RBT: {
        serverKey: 'RBT',
        i18nKey: 'common.commissionType.rebate'
    },
    CMS: {
        serverKey: 'CMS',
        i18nKey: 'common.commissionType.commission'
    },
    MKU: {
        serverKey: 'MKU',
        i18nKey: 'common.commissionType.diff'
    }
};

export const COMMISSION_STATUS = {
    PROCESSING: {
        serverKey: 0,
        i18nKey: 'common.commissionStatus.processing',
        color: COLOR.ORANGE
    },
    COMPLETED: {
        serverKey: 1,
        i18nKey: 'common.commissionStatus.completed',
        color: COLOR.GREEN
    }
};

export const WALLET_CHAIN = {
    TRC20: {
        serverKey: 'TRC20',
        label: 'TRC20'
    },
    ERC20: {
        serverKey: 'ERC20',
        label: 'ERC20'
    }
};

export const SERVER_DATE_TIME_KEY = 'datetime';

export const SYSTEM_REFERRAL_LINK_NAME = {
    /*=============================================
    =          inviter role as MANAGER           =
    =============================================*/
    STANDARD_IB: {
        serverKey: 'STANDARD_IB',
        i18nKey:
            'common.ibAccount.commission.systemReferralLinkNames.manager.standardIB'
    },
    ECN_IB: {
        serverKey: 'ECN_IB',
        i18nKey:
            'common.ibAccount.commission.systemReferralLinkNames.manager.ecnIB'
    },
    PROFESSIONAL_IB: {
        serverKey: 'PROFESSIONAL_IB',
        i18nKey:
            'common.ibAccount.commission.systemReferralLinkNames.manager.professionalIB'
    },
    STANDARD_CLIENT: {
        serverKey: 'STANDARD_CLIENT',
        i18nKey:
            'common.ibAccount.commission.systemReferralLinkNames.manager.standardClient'
    },
    ECN_CLIENT: {
        serverKey: 'ECN_CLIENT',
        i18nKey:
            'common.ibAccount.commission.systemReferralLinkNames.manager.ecnClient'
    },
    PROFESSIONAL_CLIENT: {
        serverKey: 'PROFESSIONAL_CLIENT',
        i18nKey:
            'common.ibAccount.commission.systemReferralLinkNames.manager.professionalClient'
    },
    /*=============================================
    =          inviter role as IB                 =
    =============================================*/
    STANDARD: {
        serverKey: 'STANDARD',
        i18nKey:
            'common.ibAccount.commission.systemReferralLinkNames.ib.standard'
    },
    STANDARD_10: {
        serverKey: 'STANDARD_10',
        i18nKey:
            'common.ibAccount.commission.systemReferralLinkNames.ib.standard10'
    },
    STANDARD_20: {
        serverKey: 'STANDARD_20',
        i18nKey:
            'common.ibAccount.commission.systemReferralLinkNames.ib.standard20'
    },
    STANDARD_30: {
        serverKey: 'STANDARD_30',
        i18nKey:
            'common.ibAccount.commission.systemReferralLinkNames.ib.standard30'
    },
    PROFESSIONAL: {
        serverKey: 'PROFESSIONAL',
        i18nKey:
            'common.ibAccount.commission.systemReferralLinkNames.ib.professional'
    },
    PROFESSIONAL_10: {
        serverKey: 'PROFESSIONAL_10',
        i18nKey:
            'common.ibAccount.commission.systemReferralLinkNames.ib.professional10'
    },
    PROFESSIONAL_20: {
        serverKey: 'PROFESSIONAL_20',
        i18nKey:
            'common.ibAccount.commission.systemReferralLinkNames.ib.professional20'
    },
    PROFESSIONAL_30: {
        serverKey: 'PROFESSIONAL_30',
        i18nKey:
            'common.ibAccount.commission.systemReferralLinkNames.ib.professional30'
    }
};

export const REWARD_IMAGE_BASE_PATH = `${process.env.REACT_APP_API_BASE_URL}/promotion/reward/static`;

export const RED_PACKET_DOWNLOAD_APP_PATH = 'http://qr.hotlnk.cn/79846994191';

export const FACTOR_AUTHENTICATION = {
    SMS: 'sms'
};

export const HISTORICAL_TITLE = {
    A3: {
        serverKey: 'A3',
        Icon: TitleMedalChiefIco
    },
    A2: {
        serverKey: 'A2',
        Icon: TitleMedalSeniorIco
    },
    A1: {
        serverKey: 'A1',
        Icon: TitleMedalGeneralIco
    }
};

export const PROFILE_PIC_IMG = {
    [PERSONAL_TITLE.MISSES.serverKey]: FemaleProfilePicImg,
    [PERSONAL_TITLE.MISTER.serverKey]: MaleProfilePicImg
};
